export function recruit(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  [],
				detail: []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			open : function(index){
				this.detail = this.data[index];
				this.toggle = true;
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<ul class="list clearfix">
				<li @click="open(index) , changing()" v-for="(items , index) in data">
					<i class="job" v-text="items.acf.cate"></i>
					<div class="bg" :style="{ 'background-image': 'url('+items.acf.main+')' }"></div>
					<dl>
						<dt v-text="items.title.rendered"></dt>
						<dd class="border" v-html="items.acf.catch"></dd>
					</dl>
					<ul class="meta">
						<li class="desc" data-text="参考価格" v-text="items.acf.money"></li>
						<li class="desc" data-text="名入れ" v-html="items.acf.area"></li>
					</ul>
				</li>
			</ul>
            <transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner recruit-component">
						<div class="contanier">

							<dl class="mes">
								<dt v-text="detail.title.rendered"></dt>
								<dd v-html="detail.acf.catch"></dd>
							</dl>

							<h2><i class="fas fa-clipboard-list"></i>商品内容</h2>
							<div class="mainphoto" :style="{ 'background-image': 'url('+detail.acf.main+')' }"></div>
							<div class="text" v-html="detail.acf.message"></div>

                            <div class="btns mini" v-if="detail.acf.youtube != ''"><a :href="detail.acf.youtube" target="_blank" rel="nofollow"><i class="fab fa-youtube"></i>動画を見る</a></div>

							<h2><i class="fas fa-clipboard-list"></i>商品詳細</h2>
							<div class="tablebox mb" v-html="detail.acf.table"></div>

                            <h2><i class="fas fa-coins"></i>料金表</h2>
							<div class="tablebox mb" v-html="detail.acf.table2"></div>

                            <div class="mb">
                                <div class="smlcontent" v-for="(items , index) in detail.acf.content">
                                    <div class="bg" :style="{ 'background-image': 'url('+items.img+')' }"></div>
                                    <dl>
                                        <dt v-text="items.title"></dt>
                                        <dd v-html="items.description"></dd>
                                    </dl>
                                </div>

                                <div class="smlcontent" v-for="(items , index) in detail.acf.content2">
                                    <h2 v-html="items.title"></h2>
                                    <div class="bg" :style="{ 'background-image': 'url('+items.img+')' }"></div>
                                    <p v-html="items.description"></p>
                                </div>
                            </div>

                            <div class="mb">
                                <h2 v-if="detail.acf.gallery != ''"><i class="fas fa-image"></i>ギャラリー</h2>
                                <ul class="gallery">
                                    <li v-for="(items , index) in detail.acf.gallery">
                                        <div class="bg" :style="{ 'background-image': 'url('+items.img+')' }"></div>
                                    </li>
                                </ul>
                            </div>

							<slot></slot>

                            <figure class="push-bnr"><a href="https://shop.push-stick.com/" target="_blank" rel="nofollow"><img src="/dist/images/bnr.jpg" alt="名入れ無し商品(100個以下)はこちら" /></a></figure>
						</div>		
					</div>
				</div>
			</transition>
		</div>
		`
	}
	
	return compornent;
}