export function business(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  {
					acf : {
						title : '',
						description : '',
						image: ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<div class="relative">
				<div class="bg" :style="{ 'background-image': 'url('+data.acf.image+')' }"></div>
				<div class="display texter">
					<dl>
						<dt v-html="data.acf.title"></dt>
						<dd v-html="data.acf.description"></dd>
						<dd class="btns">
							<a @click="toggle = true , changing()" class="custum-bg">さらに詳しく</a>
						</dd>
					</dl>
				</div>
			</div>
            <transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner">
						<div class="contanier">
							<h2><i class="fab fa-youtube"></i>紹介動画</h2>
                            <div class="tube" v-html="data.acf.youtube"></div>
                            <figure class="under"><img :src="data.acf.under" alt="イメージ写真" /></figure>

							<h2><i class="fas fa-clipboard-list"></i>プッシュスティックとは？</h2>
							<div class="repbox">
								<div class="bg" :style="{ 'background-image': 'url('+data.acf.rep_photo+')' }"></div>
                                <div class="tex" v-html="data.acf.rep_message"></div>
							</div>
							<div class="repbox">
								<div class="bg" :style="{ 'background-image': 'url('+data.acf.rep_photo2+')' }"></div>
                                <div class="tex" v-html="data.acf.rep_message2"></div>
							</div>
						</div>		
					</div>
				</div>
			</transition>

		</div>
		`
	}
	return compornent;
}